'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import Link from '@next-app/components/elements/Link/Link';
import Button from '../elements/Button/Button';
import GenericImage from '../elements/GenericImage/GenericImage';
import myImage from '../../../public/assets/images/rewards-ll-logo-blue.svg';
import { popOverContentNames } from '@next-app/config/elementNameConfig';
import classes from './login-flyout.module.scss';
import Conditional from '../Conditional';
import Flyout from '../elements/Flyout/Flyout';
import { logoutUser } from '@next-app/utils/authenticationUser';
import { Link as ILink } from '@next-app/interface/Common';
import { DynamicDTMEvent } from '@next-app/utils/dtm-event';
import dynamic from 'next/dynamic';

const GSAUserRedirectModal = dynamic(
  () =>
    import('@next-app/components/GSAUserRedirectModal/GSAUserRedirectModal'),
  { ssr: false },
);

const CreateAccountModal = dynamic(
  () => import('@next-app/components/CreateAccountModal/CreateAccountModal'),
  { ssr: false },
);

const Modal = (link: ILink) => {
  const router = useRouter();
  const [showCreateAccModal, setShowCreateAccModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { sessionInfo } = useSelector((state: any) => state.init);

  if (!Object.keys(sessionInfo).length) {
    return;
  }

  const {
    profile,
    featureToggles,
    rewardsCustomer,
    dynSess: { dynSessConfNumber },
  } = sessionInfo.sessionInfo;

  const { rewardsEnabled = false } = featureToggles || {};

  let isMarketing: {
    length: number;
    map: (
      // eslint-disable-next-line no-unused-vars
      arg0: (item: any, key: any) => React.JSX.Element,
    ) => React.JSX.Element;
  };

  let rewardPoints = rewardsCustomer?.rewardPoints;

  if (rewardsCustomer) {
    const { messages } = rewardsCustomer;
    if (messages) {
      const { marketing } = messages;
      isMarketing = marketing;
    }
  }

  const { firstName, loginStatus, gsaUser } = profile;

  const handleLogout = async () => {
    await logoutUser(profile?.id, dynSessConfNumber);
    router.push('/');
  };
  const LoggedIn = () => {
    return (
      <div
        className={`${classes['popOverContent-menus']}`}
        {...{ s_regionid: 'loginModal' }}
      >
        <span className={`${classes['welcome-text']} fw-bold mb-3`}>
          {' '}
          {popOverContentNames.welcomeText}, {firstName}!
        </span>
        <ul>
          <li>
            <Link href="/order-history">{popOverContentNames.ordersText}</Link>
          </li>
          <li>
            <Link href="/my-profile">{popOverContentNames.profileText}</Link>
          </li>
          <li>
            <Link href="/address-book-page">
              {popOverContentNames.addressBookText}
            </Link>
          </li>
          <li>
            <Link href="/payment-methods">
              {popOverContentNames.walletText}
            </Link>
          </li>
          <li>
            <Link href="/my-shoppinglist-page">
              {popOverContentNames.shoppingListsText}
            </Link>
          </li>
          <li>
            <Link href="/lists-and-registries/registry-list">
              {popOverContentNames.registriesText}
            </Link>
          </li>
          <li>
            <Link href="/help/preference-center?current-user=true">
              {popOverContentNames.preferencesText}
            </Link>
          </li>
        </ul>
        <Conditional if={rewardsEnabled && !gsaUser}>
          <div className={`${classes['user-reward-section']}`}>
            <Link href="/rewards">
              <GenericImage src={myImage} alt="" width={150} height={75} />
            </Link>
            <span
              className={`${classes['rewards-points']} mb-2 fw-bold text-dark`}
            >
              {rewardPoints} {popOverContentNames.pointsText}
            </span>
            <Conditional if={isMarketing && isMarketing?.length > 0}>
              {isMarketing &&
                isMarketing.map((item: any, key: any) => {
                  return (
                    <div key={key} className={`${classes['reward-messages']}`}>
                      {item}
                    </div>
                  );
                })}
            </Conditional>
          </div>
        </Conditional>
        <Link
          data-testid="sign-out-button"
          className={`${classes['sign-out-button']}`}
          href="#"
          onClick={handleLogout}
        >
          {popOverContentNames.signOutText}
        </Link>
        <br />
        <Button buttonSize="full" link="/my-account" variant="primary">
          {popOverContentNames.accountHomeText}
        </Button>
      </div>
    );
  };

  const SignedOut = () => {
    return (
      <>
        {rewardsEnabled && (
          <div className={`${classes['rewards-menu-section']}`}>
            <Link href="/rewards">
              <GenericImage src={myImage} alt="" width={150} height={75} />
            </Link>
            <ul>
              <li>
                <Link href="/rewards">{popOverContentNames.earnText}</Link>
              </li>
              <li>
                <Link href="/rewards">{popOverContentNames.redeemText}</Link>
              </li>
              <li>
                <Link href="/rewards">{popOverContentNames.saveText}</Link>
              </li>
            </ul>
            <Link className={`${classes['learn-more-text']}`} href="/rewards">
              {popOverContentNames.learnMoreText}
            </Link>
          </div>
        )}
        <Button
          type="button"
          customClass={`${classes['padding-btn']} mb-2`}
          data-bs-toggle="modal"
          data-bs-target="#signInModal"
          onClick={() => {
            DynamicDTMEvent({ event: 'loginStart' });
          }}
          automationId="sal_signin"
        >
          Sign In
        </Button>
        <Button
          data-bs-toggle="modal"
          data-bs-target="#dynamicModalWrapper"
          variant="secondary"
          customClass={`${classes['padding-btn']}`}
          s_objectid="global"
          onClick={() => {
            DynamicDTMEvent({ event: 'registerStart' });
            setShowCreateAccModal(true);
          }}
        >
          Create Account
        </Button>
      </>
    );
  };

  return (
    <>
      <span
        className={classes['account-wrapper']}
        automation-id="nh_account_icon"
        id="signin-popover"
        data-testid="reward-icon-mobile"
        role="button"
        tabIndex={0}
        data-toggle="popover"
        data-trigger="focus"
        data-bs-trigger="hover focus"
        data-placement="bottom"
        data-html="true"
        data-offset="10"
        data-content=""
        data-original-title=""
        title=""
      >
        <Link
          data-testid="signin-icon-link"
          href={loginStatus !== 'guest' ? '/my-account/' : undefined}
          className={`${classes['signin-icon']} d-none d-lg-block f-enabled`}
          s_objectid={link?.s_objectId || ''}
        ></Link>
        <Link
          className={`${classes['signin-icon']}  d-lg-none f-enabled`}
        ></Link>
        <Link
          className={`${classes['text-icon']}`}
          role="presentation"
          aria-hidden="true"
          tabIndex={-1}
          href={loginStatus !== 'guest' ? '/my-account/' : undefined}
          s_objectid={link?.s_objectId || ''}
        >
          {loginStatus === 'guest'
            ? popOverContentNames.accountText
            : firstName}
        </Link>
        <Flyout
          data-testid="popOverContent"
          customClass={`popOverContent ${classes['flyout-modal']}`}
        >
          {loginStatus != 'guest' ? <LoggedIn /> : <SignedOut />}
        </Flyout>
      </span>
      <Conditional if={loginStatus != 'guest' && gsaUser}>
        <GSAUserRedirectModal />
      </Conditional>
      <Conditional if={showCreateAccModal}>
        <CreateAccountModal
          showCreateAccModal={showCreateAccModal}
          setShowCreateAccModal={setShowCreateAccModal}
          setShowSuccessModal={setShowSuccessModal}
          showSuccessModal={showSuccessModal}
        />
      </Conditional>
    </>
  );
};

export default Modal;
