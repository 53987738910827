import urlMapping from '@next-app/config/urlMapping';
import { RedirectSearchSubmit } from '@next-app/interface/Common';
import { makeFetchCall } from '@next-app/utils/fetch-data';

export const fetchTypeaheadSuggestions = async (
  searchTerm: string,
  setData: Function,
  signal?: AbortController | null,
) => {
  try {
    const responseData = await makeFetchCall({
      endpoint: `${urlMapping.searchSuggestedNttUrl}/${searchTerm}`,
      signal: signal,
    });
    setData(responseData.typeAheadResponse);
    return responseData.typeAheadResponse;
  } catch (error) {
    if (error instanceof Error && error.name !== 'AbortError') {
      console.error('Error fetching searching products:', error);
    }
    setData([]);
    return [];
  }
};

export const fetchFlyoutProductSuggestions = async (
  searchTerm: string,
  data: any,
  setData: Function,
  sessionConfirmationId: string | undefined,
) => {
  try {
    const responseData = await makeFetchCall({
      endpoint: urlMapping.searchFlyoutUrl,
      params: {
        Ntt: searchTerm,
        _dynSessConf: sessionConfirmationId,
      },
    });
    setData({
      ...data,
      products: responseData.products,
    });
  } catch (error) {
    console.error('Error fetching searching products:', error);
  }
};

export const formatUSPrice = (price: number) => {
  if (typeof price !== 'number') {
    throw new TypeError('Input must be a number');
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
};

export const searchSubmit = async (searchTerm: string) => {
  try {
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    const responseData = await makeFetchCall({
      endpoint: `${urlMapping.searchRedirectUrl}${encodedSearchTerm}`,
    });
    return responseData;
  } catch (error) {
    console.error('Error fetching search redirect response:', error);
    return [];
  }
};
export const handleSearchRedirect = async (inputVal: string, router: any) => {
  const response: RedirectSearchSubmit | null = await searchSubmit(inputVal);

  if (response?.['@type'] === 'RedirectAwareContentInclude' && response?.link) {
    const linkURL = response.link.split('Ntt=');
    router.push(
      linkURL.length === 1
        ? linkURL[0]
        : linkURL[0] + 'Ntt=' + encodeURIComponent(linkURL[1]),
    );
  }
};
