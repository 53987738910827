import Link from '@next-app/components/elements/Link/Link';
import classes from './lakeshore-icon.module.scss';

export default function LakeshoreIcon() {
  return (
    <Link
      className={`header-link ${classes['ll-logo']} f-enabled`}
      href="/"
      data-updated="false"
      data-testid="lakeshore-icon"
      aria-label="Go to lakeshorelearning.com"
    >
      <span
        automation-id="lakeshore_logo"
        className={`logos-sprites ${classes['ll-logo-red']}`}
        title="Lakeshore® Learning Materials. Teacher supply store offering educational products, classroom furniture, decor and so much more."
      ></span>
      <span style={{ display: 'none' }} className="ae-compliance-indent">
        {' '}
        Lakeshore Home Page Logo{' '}
      </span>
    </Link>
  );
}
