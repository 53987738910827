/**
 * Debounces a function by delaying its execution until a certain delay has passed.
 * If the debounced function is called multiple times within the delay, the delay is reset each time.
 * @param {Function} func - The function to be executed after the delay.
 * @param {number} delay - The delay in milliseconds before executing the function (default: 0).
 * @returns {void} - null.
 */
let timeoutId: ReturnType<typeof setTimeout>;

export const debounce = (
  func: Function,
  delay = 0,
): ReturnType<typeof setTimeout> => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    func();
  }, delay);
  return timeoutId;
};
