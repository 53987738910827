import React from 'react';
import classes from '@next-app/components/Breadcrumbs/breadcrumb.module.scss';

interface BreadcrumbItem {
  name?: string;
  href?: string;
}

interface CategoryBreadcrumbsProps {
  breadcrumbData?: BreadcrumbItem[];
}

const CategoryBreadcrumbs: React.FC<CategoryBreadcrumbsProps> = ({
  breadcrumbData = [],
}) => {
  return (
    <div className={`${classes.breadcrumb}`}>
      {breadcrumbData.map((item, index) => (
        <span key={index}>
          {item.href ? (
            <a href={item.href}>{item.name}</a>
          ) : (
            <span>{item.name}</span>
          )}
          {index < breadcrumbData.length - 1 && (
            <span className={classes.separator}> </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default CategoryBreadcrumbs;
