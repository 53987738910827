import CategoryBreadcrumbs from '@next-app/components/Category/CategoryBreadcrumbs/CategoryBreadcrumbs';
import CategoryTitle from '@next-app/components/Category/CategoryTitle/CategoryTitle';
interface ContentItem {
  LeftContent?: { ['@type']?: string; content?: any }[];
  RightContent?: { content?: { name?: string }[] }[];
}

interface OneThirdTwoThirdProps {
  contentItem: ContentItem;
}

export default function OneThirdTwoThird({
  contentItem,
}: OneThirdTwoThirdProps) {
  const { RightContent = [], LeftContent = [] } = contentItem;
  const categoryPageTitle = RightContent[0]?.content?.[1]?.name ?? '';

  const StaticBreadcrumb = LeftContent?.find(
    (item) => item['@type'] === 'PageContent-StaticBreadcrumbs',
  );

  const breadcrumbData = StaticBreadcrumb?.content;

  return (
    <div className="pdp-main-container pdp">
      <div className="row">
        <div className="col-md-3 d-none d-lg-block">
          <CategoryBreadcrumbs breadcrumbData={breadcrumbData} />
        </div>
        <div className="col-lg-9 col-xs-12">
          <CategoryTitle categoryPageTitle={categoryPageTitle} />
        </div>
      </div>
    </div>
  );
}
