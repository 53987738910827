import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export const GetSearchTermNtt = ({
  setSearchState,
}: {
  setSearchState: Function;
}) => {
  const searchTerms = useSearchParams();
  const searchTermNtt = searchTerms.get('Ntt');
  useEffect(() => {
    if (searchTermNtt)
      setSearchState(
        (prev: {
          inputVal: string;
          showSuggestions: boolean;
          showRecents: boolean;
          error: false;
          showBackButton: boolean;
        }) => ({ ...prev, inputVal: searchTermNtt }),
      );
  }, [searchTermNtt]);

  return null;
};
