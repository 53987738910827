import { formDataFields } from '@next-app/constants/formdata';
import { IContactFormData } from '@next-app/interface/ContactForm';

export const validateEmail = (email: string) => {
  const emailPattern = formDataFields.email.validPatternRegExp;
  if (email && !emailPattern.test(email)) {
    return formDataFields.email.invalidMessage;
  }
};

export const validatePhone = (phoneNo: string) => {
  const phoneNoPattern = formDataFields.phoneNo.validPatternRegExp;
  if (phoneNo && !phoneNoPattern.test(phoneNo)) {
    return formDataFields.phoneNo.invalidMessage;
  }
};

export const validateZipCode = (zipCode: string) => {
  const zipPattern = formDataFields.zipCode.validPatternRegExp;
  let ZipValregex1 = new RegExp(zipPattern[1]);
  let ZipValregex2 = new RegExp(zipPattern[2]);
  if (!ZipValregex1.test(zipCode) && !ZipValregex2.test(zipCode)) {
    return formDataFields.zipCode.invalidMessage;
  }
};

export const checkValidation = (formData: IContactFormData) => {
  let errors = {
    fname: '',
    lname: '',
    phoneNo: '',
    zipCode: '',
    email: '',
    message: '',
  };

  if (!formData.fname) errors.fname = 'Please enter a first name.';
  if (!formData.lname) errors.lname = 'Please enter a last name.';
  if (!formData.phoneNo) errors.phoneNo = 'Please enter a phone number.';
  if (!formData.zipCode) errors.zipCode = 'Please enter Zip/Postal Code.';
  if (!formData.email) errors.email = 'Please enter an email address';
  if (!formData.message) errors.message = 'Please enter a message.';

  const zip = validateZipCode(formData.zipCode);
  if (zip) {
    errors.zipCode = zip;
  }

  const phone = validatePhone(formData.phoneNo);
  if (phone) {
    errors.phoneNo = phone;
  }
  const email = validateEmail(formData.email);
  if (email) {
    errors.email = email;
  }

  return errors;
};

export const specialCharactersField = ['email', 'message'];

export function removeSpecialCharacters(input: string) {
  return input.replace(/[^a-zA-Z0-9\s]/g, '');
}

export const formatPhoneNumber = (input: string) => {
  const cleaned = input.replace(/\D/g, '');
  let formatted = '';
  if (cleaned.length > 0) {
    formatted += `(${cleaned.substring(0, 3)}`;
  }
  if (cleaned.length >= 4) {
    formatted += `) ${cleaned.substring(3, 6)}`;
  }
  if (cleaned.length >= 7) {
    formatted += `-${cleaned.substring(6, 10)}`;
  }

  return formatted;
};

export const getUnformattedNumber = (phoneNumber: string) =>
  phoneNumber.replace(/\D/g, '');
