import React from 'react';
import classes from './category-title.module.scss';
interface CategoryTitleProps {
  categoryPageTitle: string;
}
const CategoryTitle: React.FC<CategoryTitleProps> = ({ categoryPageTitle }) => {
  return (
    <div className={classes['title']}>
      <h2>{categoryPageTitle}</h2>
    </div>
  );
};

export default CategoryTitle;
